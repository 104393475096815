import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Advanced extends React.Component {
    render() {
        let sectionsInfo = _.get(this.props, 'pageContext.frontmatter.sections', null)
      
        console.log('tut props', sectionsInfo)
        let itemsin = [{title: '1'},{title: '1'},{title: '1'},{title: '1'}]
        console.log(itemsin);
        return (
            <Layout {...this.props}>
              
                {(!_.get(this.props, 'pageContext.frontmatter.hide_title', null)) && (
                  <header className="page-header inner-sm outer">
                    <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                  </header>
                )}
                <div className="block-content inner">
                    <div className="grid grid-col-2">
                        {_.map(_.get(this.props, 'pageContext.frontmatter.sections', null), (section, section_idx) => {
                            console.log('section', section, section_idx);
                            let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                            let Component = components[component];
                            
                            return (
                                <div key={section_idx} className="grid-item">
                                  <Component key={section_idx} {...this.props} section={section} site={this.props.pageContext.site} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Layout>
        );
    }
}
